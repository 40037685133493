<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-user" /><strong>User Events</strong>
      </CCardHeader>
      <CCardBody>
        <div class="card-header-actions">
          <small class="text-muted">
            <multiselect size="sm" :limit="2" :clear-on-select="false" :close-on-select="true" :multiple="false"
              :options="perPage" :preselect-first="false" :preserve-search="false" @input="checkPage()"
              placeholder="PerPage" v-model="tableperpage"></multiselect>
          </small>
        </div>
        <!-- Main table element -->
        <CDataTable :items="items" :fields="fields" :dark="false" :items-per-page="tableperpage" pagination sorter hover
          striped border small fixed table-filter light>
          <template #createdAt="{ item }">
            <td>{{ changeDateFormate(item.createdAt) }}</td>
          </template>

          <template #action="{ item }">
            <td>
              <span @click="editEventModal(item)">
                <CImg src="img/logo/edit.png" class="img-fluid ml-1" title="Inactive" style="cursor: pointer;"></CImg>
              </span>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>


    <!-- Edit User Event Modal  -->
    <CModal title="Edit User Event" :show.sync="editEvent" size="lg" :centered="true" scrollable color="dark">
      <div class="p-2">
        <CForm>
          <CRow>
            <CCol md="12">
              <label for="subject">Subject</label>
              <CInput id="subject" v-model="uie_data.subject" placeholder="Enter Subject" />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <label for="message">Message</label>
              <CTextarea id="message" v-model="uie_data.message" rows="15" placeholder="Enter Message" />

            </CCol>
          </CRow>
        </CForm>
        <CRow>
          <CCol md="12">
            <CButton class="col-md-12" color="primary" @click="updateEvents()" :disabled="loading">Update</CButton>
          </CCol>
        </CRow>
      </div>
      <template #footer>
        &nbsp;
      </template>
    </CModal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from 'moment'
import ManagerService from './../../services/managerControlService'
import { CModal, CTextarea } from "@coreui/vue";
export default {
  components: { Multiselect },
  data() {
    return {
      editEvent: false,
      input: {},
      tableperpage: 20,
      perPage: [20, 50, 100],
      items: [],
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'subject', label: 'Subject' },
        { key: 'message', label: 'Message' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'action', label: 'Action' },
      ],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      events: [],
      uie_data: {},
      loading: false,
    }
  },
  async mounted() {
    if (window.localStorage.getItem('user') == 'null') {
      alert('Please Enter User Name and Password')
      window.localStorage.setItem("user", null);
      window.localStorage.setItem("status", null);
      window.localStorage.setItem("email", null);
      this.$router.push('/pages/login')
    }
    if (localStorage.getItem("user_event_data") != null)
      this.tableperpage = parseInt(localStorage.getItem("user_event_data"));
    this.getEventData()

  },
  methods: {
    checkPage() {
      localStorage.setItem("user_event_data", this.tableperpage);
    },
    async getEventData() {
      let response
      try {
        response = await ManagerService.getUserEvents()
        this.events = response.data
        this.items = this.events
        this.totalRows = this.items.length
      } catch (e) {
        console.error("Error in login")
        console.log(e)
      }
    },
    async updateEvents() {
      this.loading = true;
      let payload = {
        "eid": this.uie_data._id,
        "changeObj": {
          "subject": this.uie_data.subject,
          "message": this.uie_data.message
        }
      }
      let response = await ManagerService.updateUserEvents(payload)
      if (response.result) {
        Swal.fire({ title: 'Success!', text: response.message, icon: 'success', confirmButtonText: 'Ok', position: 'top-end', showConfirmButton: false, timer: 1000 })
        this.closeModal();
        this.getEventData();
      }
      this.loading = false;
    },
    closeModal() {
      this.editEvent = false
    },
    editEventModal(data) {
      this.uie_data = data
      this.editEvent = true
    },
    changeDateFormate(d) {
      return moment.utc(d).format('MMMM D, YYYY, HH A')
    },
  }
}
</script>